export const formatForShortlist = function (influencer) {
  return {
    name: influencer.name,
    email: influencer.email || "",
    phone: (influencer.country_code || "") + (influencer.phone || ""),
    address: influencer.address || "",
    dob: influencer.dob || "",
    dob_of_child_1: influencer.dob_of_child_1,
    dob_of_child_2: influencer.dob_of_child_2,
    dob_of_child_3: influencer.dob_of_child_3,
    instagram_handle: influencer.instagram_handle,
    instagram_id: influencer.instagram_id,
    instagram_profile_pic_url:
      influencer.instagram_profile_pic_url || "/cat.jpg",
    is_instagram_business_account: influencer.is_instagram_business_account,
    instagram_verified: influencer.instagram_verified,
    gender: influencer.gender,
    tier: influencer.tier || 0,
    engagement_updated_at: influencer.engagement_updated_at,
    primary_category: influencer.primary_category || "",
    secondary_categories: influencer.secondary_categories || [""],
    city: influencer.city,
    state: influencer.state,
    region: influencer.region,
    instagram_image_quality: influencer.instagram_image_quality || 0,
    instagram_video_quality: influencer.instagram_video_quality || 0,
    instagram_followers: influencer.instagram_followers || 0,
    instagram_total_engagement: influencer.instagram_total_engagement || 0,
    instagram_total_engagement_rate:
      influencer.instagram_total_engagement_rate || 0,
    status: "master",
    notes: "",
    estimated_cost: 0,
    offer_cost: 0,
    brand_cost: 0,
    margin: 0,
    expected_engagement: 0,
    cpe: 0,
    instagram_image_pricing: influencer.instagram_image_pricing || 0,
    instagram_carousel_pricing: influencer.instagram_carousel_pricing || 0,
    instagram_video_pricing: influencer.instagram_video_pricing || 0,
    instagram_static_story_pricing:
      influencer.instagram_static_story_pricing || 0,
    instagram_video_story_pricing:
      influencer.instagram_video_story_pricing || 0,
    instagram_reels_pricing: influencer.instagram_reels_pricing || 0,
    instagram_igtv_pricing: influencer.instagram_igtv_pricing || 0,
    image_engagement: influencer.image_engagement || 0,
    carousel_engagement: influencer.carousel_engagement || 0,
    video_engagement: influencer.video_engagement || 0,
    static_story_engagement: influencer.image_engagement || 0,
    video_story_engagement: influencer.image_engagement || 0,
    reels_engagement: influencer.reels_engagement || 0,
    igtv_engagement: influencer.igtv_engagement || 0,
    image_engagement_rate: influencer.image_engagement_rate || 0,
    carousel_engagement_rate: influencer.carousel_engagement_rate || 0,
    video_engagement_rate: influencer.video_engagement_rate || 0,
    static_story_engagement_rate: influencer.image_engagement_rate || 0,
    video_story_engagement_rate: influencer.image_engagement_rate || 0,
    reels_engagement_rate: influencer.reels_engagement_rate || 0,
    igtv_engagement_rate: influencer.igtv_engagement_rate || 0,
    content_plan: [],
    selected: false,
    exists: true,
    list_poc: "", // List point-of-contact
    user_labels: influencer.user_labels,
    youtube_integrated_video_pricing:
      influencer.youtube_integrated_video_pricing || 0,
    youtube_dedicated_video_pricing:
      influencer.youtube_dedicated_video_pricing || 0,
    id: influencer.id,
    categories: influencer.categories,
    mix_count: influencer.mix_count || 0,
    reels_pricing: influencer.reels_pricing || 0
  };
};
