import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  tokenCost: [],
  tokenCostStatus:"idle",
  tokenHistory:[],
  tokenHistoryStatus:"idle",
  addToken:[],
};

export const getTokenHistory = createAsyncThunk(
  "allTokenData/getTokenHistory",
  async (gData) => {
    const {data} =  await axios.get(`/apptoken/apiexpenditure?user_id=${gData.user_id}`);
    return data;
  }
);

export const getAllTokenCost = createAsyncThunk(
  "allTokenData/getAllTokenCost",
  async () => {
    const data =  await axios.get(`/apptoken/apicost`);
    //console.log("allUsersSlice",data);
    return data;
  }
);

const iqTokenSlice = createSlice({
  name: "allTokenData",
  initialState,
  reducers: {
    emptyUserDataRedux: (state, action) => {
      state.users = [];
    },
    emptyAccountUsers: (state, action) => {
      state.accounts = [];
    }
  },
  extraReducers: {
    [getTokenHistory.pending]: (state, action) => {
      state.tokenHistoryStatus = "loading";
      state.tokenHistory = [];
    },
    [getTokenHistory.fulfilled]: (state, action) => {
      state.tokenHistoryStatus = "success";
      state.tokenHistory = action.payload;
    },
    [getTokenHistory.rejected]: (state, action) => {
      state.tokenHistoryStatus = "failed";
    },
    [getAllTokenCost.pending]: (state, action) => {
      state.tokenCostStatus = "loading";
    },
    [getAllTokenCost.fulfilled]: (state, action) => {
      state.tokenCostStatus = "success";
      state.tokenCost = action.payload.data;
    },
    [getAllTokenCost.rejected]: (state, action) => {
      state.tokenCostStatus = "failed";
    },
  },
});

export default iqTokenSlice.reducer;
export const {
   emptyUserDataRedux, emptyAccountUsers
} = iqTokenSlice.actions;
