import { CircularProgress, Grid, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";

import DialogTitle from "@material-ui/core/DialogTitle";
import { useHistory } from "react-router";

import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { grantProjectAccess } from "../../new_api/api";
const useStyles = makeStyles((theme) => ({
    title: {
        borderTop: "7px solid #2e75bb",
        paddingTop: "37px",
        [theme.breakpoints.up("sm")]: {
            width: "464px",
        },
        "& h2": {
            color: "#41414",
            fontWeight: 500,
            fontSize: "14px",
        },
    },
    button: {
        fontSize: "12px",
        lineHeight: 1.5,
    },
    text: {
        fontSize: "12px",
        fontStyle: "italic",
        textAlign: "left",
        color: "#414141",
        lineHeight: 1.5,
    },
    backdrop: {
        backdropFilter: "blur(3px)",
        backgroundColor: "rgba(0, 0, 0, 0.28)",
    },
}));

export default function GrantModal() {
    const { projectId, userId } = useParams();
    const history = useHistory()
    const classes = useStyles();
    const handleClose = () => {
        history.push(`/projects/${projectId}`)
    }

    const onSubmit = async () => {
        const payload = {
            "user_id": parseInt(userId)
        }
        try {
            const res = await grantProjectAccess(projectId, payload)
            toast.success(res.message)
            handleClose()
        } catch (error) {
            toast.error("Something went wrong")
        }
    };

    return (
        <div>
            <Dialog
                BackdropProps={{
                    classes: {
                        root: classes.backdrop,
                    },
                }}
                open={true}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle className={classes.title} id="alert-dialog-title">
                    You sure want to grant Access to this Campaign?
                </DialogTitle>
                <DialogActions>
                    <Grid container justify="space-between" alignItems="right">
                        <Grid item>

                            <Button
                                className={classes.button}
                                onClick={onSubmit}
                                color="primary"
                                variant="contained"
                                autoFocus
                            >Grant Access
                            </Button>
                            <Button
                                className={classes.button}
                                onClick={handleClose}
                                color="secondary"
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
    );
}
