import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import opMetricsData from '../new_api/demo_data/heatmapdata.json'

const initialState = {
    data: {},
    status: "loading",
};

export const getOpMetrics = createAsyncThunk(
    "opMetrics/getOpMetrics",
    async (omData) => {
        const { data } = await axios.get(
            `/heat_map_dashboard/?from_date=${omData.from_date}&to_date=${omData.to_date}`
        );
        return data;
    }

    // async (status) => {
    //     return opMetricsData;
    // }

);

const locationSlice = createSlice({
    name: "opMetrics",
    initialState,
    extraReducers: {
        [getOpMetrics.pending]: (state, action) => {
            state.status = "loading";
        },
        [getOpMetrics.fulfilled]: (state, action) => {
            state.status = "success";
            state.data = action.payload.data;
        },
        [getOpMetrics.rejected]: (state, action) => {
            state.status = "failed";
        },
    },
});

export default locationSlice.reducer;