import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    tags: [],
    status: ''
};

export const getProjectTags = createAsyncThunk(
    "tags/getTags",
    async (project_id) => {
        const { data } = await axios.get(`/tags/?project_id=${project_id}`);
        return data;
    }
);

const tagSlice = createSlice({
    name: "tags",
    initialState,
    extraReducers: {
        [getProjectTags.pending]: (state, action) => {
            state.status = "loading";
        },
        [getProjectTags.fulfilled]: (state, action) => {
            state.status = "success";
            state.tags = action.payload.data;
        },
        [getProjectTags.rejected]: (state, action) => {
            state.status = "failed";
        },
    },
});

export default tagSlice.reducer;
